// Packages
import $api from '@rfs-consulting/vivali-sdkjs/browser';

// Store
import { useUserStore } from '~/store/user';

// SDK
import SDK from './index';

export default class User {
  private store = useUserStore;

  constructor (
    private api: $api,
    private _sdk: SDK
  ) {};

  public async info() {
    const store = this.store();

    const res = await this.api.user.me();

    store.setInfo(res);

    return res;
  }
}