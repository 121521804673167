// Packages
import type { IModel } from '@rfs-consulting/vivali-sdkjs/types/resources/user';

export const useUserStore = defineStore('user', () => {
  const info = ref<IModel>();

  const name = computed(() => info.value?.id ? `${info.value.first_name} ${info.value.last_name}` : '');

  const isAdmin = computed(() => ['superadmin', 'admin'].includes(info.value?.type || ''))

  function setInfo(newUser: IModel) {
    info.value = newUser;
  }

  return { info, setInfo, name, isAdmin }
})