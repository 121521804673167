import $api from '@rfs-consulting/vivali-sdkjs/browser';
import Auth from './auth';
import User from './user';

export default class {
  public $api!: $api;

  public auth!: Auth;

  public user!: User;

  constructor(endpoint: string) {
    const api = new $api({});
    api.agent.setUrl(endpoint);

    const models = {
      Auth,
      User
    };

    // Initialize classes
    Object.keys(models).forEach((keyName: string) => {
      (this as any)[keyName.toLowerCase()] = new (models as any)[keyName](api, this);
    });

    this.$api = api;
  }
}