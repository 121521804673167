
import SDK from '../services';

export default defineNuxtPlugin(async (nuxtApp) => {
  const sdk = new SDK(nuxtApp.$config.public.apiEndpoint);

  return {
      provide: {
        sdk
      }
  }
});