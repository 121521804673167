// Packages
import { defineStore } from 'pinia';

// Utils
export const useAuthStore = defineStore('auth', () => {
  const access_token = ref<string | undefined>(undefined);
  const refresh_token = ref<string | undefined>(undefined);
  const email = ref('');
  const password = ref('');

  const tokens = computed(() => ({
    access_token: access_token.value,
    refresh_token: refresh_token.value,
  }));

  function setTokens(newAccessToken: string, newRefresToken: string) {
    access_token.value = newAccessToken;
    refresh_token.value = newRefresToken;
  }

  function reset() {
    access_token.value = undefined;
    refresh_token.value = undefined;
  }

  return {
    access_token,
    refresh_token,
    email,
    password,
    tokens,
    setTokens,
    reset,
  }
});
